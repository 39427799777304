<template>
  <div class="col-md-6 mt-5">
    <div class="row mb-2">
      <div class="col-6">
          <span class="dashboard-block_title">
            CMC Bulletins
          </span>
      </div>
      <div class="col-6 text-end" v-if="isCEO === true || isGranted('ROLE_BULLETIN_MANAGER')">
        <a href="#" class="dashboard-block_link" @click="$emit('addBulletinsModal')">
          Add
        </a>
      </div>
    </div>
    <div class="text-center mt-5" v-if="loading === true">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="dashboard-block" v-if="loading === false">
      <div class="text-center" v-if="bulletins.length === 0">
        No Records Found
      </div>
      <div class="bulletins" v-if="bulletins.length > 0">
        <div class="py-2" v-for="bulletin in bulletins" :key="bulletin.id">
          <div class="row">
            <div class="col-10 p-0">
              <img src="@/assets/icons/icon-news.svg" alt="">
              <span class="dashboard-block_title">
              {{ bulletin.title }}
            </span>
            </div>
            <div class="col-2 p-0 text-end">
              <span class="date-label">
                {{ bulletin.date }}
              </span>
            </div>
          </div>
          <div class="col-12 bulletins-body">
              <span v-html="bulletin.content"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Bulletins',
  props: {
    isCEO: Boolean,
    bulletins: Array,
    loading: Boolean
  }
}
</script>

<style lang="scss" scoped>

.bulletins {
  padding: 10px 10px 20px 24px;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(231,232,232,1);
  }
}

.bulletins-body {
  margin-top: 10px;
  margin-left: 10px;
  padding-left: 20px;
  border-left: 2px solid rgba(0,120,255,1)
}

.dashboard-block {
  max-height: 600px;
  min-height: 300px;
  overflow-y: auto;
  border: 1px solid rgba(231,232,232,1);
  border-radius: 12px;
  background-color: rgba(255,255,255,1);
  padding: 12px 0;
  &_title {
    color: rgba(0,0,0,1);
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 15px;
  }

  &_link {
    text-decoration: none;
    color: rgba(0,120,255,1);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 12px;
  }

  .task-for {
    color: rgba(0,0,0, .6);
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 18px;
    b {
      color: rgba(0,0,0,1);
      font-size: 14px;
      letter-spacing: 0;
      line-height: 21px;
    }
  }
  .task-title {
    opacity: 0.7;
    color: rgba(0,0,0,1);
    font-size: 15px;
    letter-spacing: 0;
    line-height: 23px;
    margin: 0;
  }
  .date-label {
    opacity: 0.4;
    color: rgba(0,0,0,1);
    font-size: 11px;
    letter-spacing: 0;
    line-height: 17px;
    text-align: right;
  }
  .row {
    width: 100%;
    margin: 0;
  }
}

</style>
