<template>
  <div class="col-md-6 mt-4">
    <div class="row mb-2">
      <div class="col-md-6">
        <span class="dashboard-block_title">
          Recent Activity
        </span>
      </div>
    </div>
    <div class="text-center mt-5" v-if="loading === true">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="dashboard-block" v-if="loading === false">
      <div class="col-12">
        <div class="text-center" v-if="activities.length === 0">
          No Records Found
        </div>
        <div class="row" v-if="activities.length > 0">
          <div
            class="col-12 activity"
            @click="$emit('showNote', activity.id)"
            v-for="activity in activities"
            :key="activity.id"
          >
            <div class="row">
              <div class="col-11">
                <div class="activity-for">
                  {{ activity.borrower }}
                </div>
                <div v-if="activity.type === 'edit' || activity.type === 'uwm_sync'">
                  <div class="activity-note">
                    <span class="assigned-task">
                      Application has been updated:
                    </span>
                    <div class="app-updated d-flex align-items-center" v-for="value in activity.changes"
                        :key="value.property">
                      <div>
                        {{ value.property }}
                      </div>
                      <div>
                        <img src="@/assets/icons/icon-value-removed.svg" alt="">
                        <span>
                          {{ value.oldValue }}
                        </span>
                      </div>
                      <div>
                        <img src="@/assets/icons/icon-value-removed-icon-value-added.svg" alt="">
                        <span>
                          {{ value.newValue }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="activity-assigner mt-2">
                    <img :src="agentPhoto(activity.agent.image)" class="me-2" alt="" v-if="activity.agent">
                    <span class="activity-assigner-name">
                      {{ activity.agent ? activity.agent.name : 'UWM sync' }}
                    </span>
                    <span class="activity-assigner-added">
                      added a note
                    </span>
                  </div>
                </div>
                <div v-else>
                  <div class="activity-note">
                    <span class="activity-note">
                      {{ activity.message }}
                    </span>
                  </div>
                  <div class="activity-assigner mt-2">
                    <img :src="agentPhoto(activity.agent.image)" class="me-2" alt="" v-if="activity.agent">
                    <span class="activity-assigner-name">
                      {{ activity.agent ? activity.agent.name: 'UWM sync' }}
                    </span>
                    <span class="activity-assigner-added">
                      added a note
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-1 p-0 text-end">
                <span class="date-label">
                  {{ activity.time }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RecentActivity',
  props: {
    loading: Boolean,
    activities: Array
  }
}
</script>

<style lang="scss" scoped>
.dashboard-block {
  max-height: 600px;
  min-height: 300px;
  overflow-y: auto;
  border: 1px solid rgba(231, 232, 232, 1);
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 1);
  padding: 12px 0;

  &_title {
    color: rgba(0, 0, 0, 1);
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 15px;
  }

  &_link {
    text-decoration: none;
    color: rgba(0, 120, 255, 1);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 12px;
  }

  .task-for {
    color: rgba(0, 0, 0, .6);
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 18px;

    b {
      color: rgba(0, 0, 0, 1);
      font-size: 14px;
      letter-spacing: 0;
      line-height: 21px;
    }
  }

  .task-title {
    opacity: 0.7;
    color: rgba(0, 0, 0, 1);
    font-size: 15px;
    letter-spacing: 0;
    line-height: 23px;
    margin: 0;
  }

  .date-label {
    opacity: 0.4;
    color: rgba(0, 0, 0, 1);
    font-size: 11px;
    letter-spacing: 0;
    line-height: 17px;
    text-align: right;
  }

  .row {
    width: 100%;
    margin: 0;
  }
}

.activity {
  padding: 10px 24px;
  transition: all .3s;
  cursor: pointer;

  &:nth-child(2n) {
    padding-top: 7px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, .05);
  }

  &-for {
    color: rgba(0, 0, 0, 1);
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 25px;
  }

  &-note {
    opacity: 0.7;
    color: rgba(0, 0, 0, 1);
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 4px;
    font-weight: 400;
  }

  &-assigner {
    img {
      height: 25px;
      width: 25px;
      border: 1px solid rgba(237, 237, 238, 1);
      border-radius: 50%;
    }

    &-name {
      color: rgba(0, 0, 0, 1);
      font-size: 14px;
      letter-spacing: 0;
      line-height: 21px;
    }

    &-added {
      opacity: 0.6;
      color: rgba(0, 0, 0, 1);
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 18px;
    }
  }
}

.app-updated {
  border: 1px solid rgba(239, 239, 239, 1);
  border-radius: 4px;
  padding: 10px 12px;
  margin-top: 5px;

  div {
    width: 33%;
    &:first-child {
      width: fit-content;
      margin-right: 10px;
    }

    span {
      color: rgba(0, 0, 0, 1);
      font-size: 11px;
      letter-spacing: 0;
      line-height: 17px;
    }

    &:nth-child(2) {
      span {
        opacity: 0.46;
      }
    }
  }
}

</style>
