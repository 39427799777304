import { render, staticRenderFns } from "./Bulletins.vue?vue&type=template&id=a3f60872&scoped=true&"
import script from "./Bulletins.vue?vue&type=script&lang=js&"
export * from "./Bulletins.vue?vue&type=script&lang=js&"
import style0 from "./Bulletins.vue?vue&type=style&index=0&id=a3f60872&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3f60872",
  null
  
)

export default component.exports