<template>
  <div>
    <div class="text-center mt-5" v-if="loading === true">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="row mb-5" v-if="loading === false">
      <div class="col-md-12 mt-3">
        <div class="d-flex justify-content-center">
          <router-link :to="{name: 'loans', query: {new_loan: true}}" class="tab-btn-start">Submit a Loan</router-link>
          <router-link :to="{name: 'funding_requests_pending', query: {newFunding: true}}" class="tab-btn-center">Submit a Funding</router-link>
          <router-link :to="{name: 'pre_approval_letter_create'}" class="tab-btn-center">Generate Pre-Approval</router-link>
          <button @click="redirectToLeadPool()" class="tab-btn-end" style="text-decoration: underline">Call Lead Pool</button>
        </div>
      </div>
      <div class="mt-3 mt-md-3" :class="isGranted('ROLE_CEO_ASSISTANT') ? 'col-md-3' : 'col-md-6'">
        <div class="agent-stats bg-light-green">
          <div class="agent-stats_title">
            {{ convertToCurrency(agentStats.loanSubmissionsMTD) }}
          </div>
          <div class="agent-stats_subtitle">
            Loan submissions MTD
          </div>
        </div>
      </div>
      <div class="col-md-3 mt-3 mt-md-3" v-if="isGranted('ROLE_CEO_ASSISTANT')">
        <div class="agent-stats bg-light-pink">
          <div class="agent-stats_title">
            {{ unitFundedMTDByCompany }}
          </div>
          <div class="agent-stats_subtitle">
            Units funded MTD by company
          </div>
        </div>
      </div>
      <div class="col-md-3 mt-3 mt-md-3">
        <div class="agent-stats bg-light-purple">
          <div class="agent-stats_title">
            {{ agentStats.unitTodayTotal }}
          </div>
          <div class="agent-stats_subtitle">
            Units funded today
          </div>
        </div>
      </div>
      <div class="col-md-3 mt-3 mt-md-3">
        <div class="agent-stats bg-light-blue">
          <div class="agent-stats_title">
            {{ agentStats.unitMonthTotal }}
          </div>
          <div class="agent-stats_subtitle">
            Units funded this month
          </div>
        </div>
      </div>
      <div class="col-md-3 mt-3">
        <div class="agent-stats bg-light-yellow">
          <div class="agent-stats_title">
            {{ agentStats.submissions }}
          </div>
          <div class="agent-stats_subtitle">
            Applications submitted
          </div>
        </div>
      </div>
      <div class="col-md-3 mt-3">
        <div class="agent-stats bg-light-grey">
          <div class="agent-stats_title">
            {{ agentStats.pipelines }}
          </div>
          <div class="agent-stats_subtitle">
            Total in pipeline
          </div>
        </div>
      </div>
      <div class="col-md-3 mt-3">
        <div class="agent-stats bg-light-turquoise">
          <div class="agent-stats_title">
            {{ agentStats.taskToday }}
          </div>
          <div class="agent-stats_subtitle">
            Tasks assigned today
          </div>
        </div>
      </div>
      <div class="col-md-3 mt-3">
        <div class="agent-stats bg-light-pink">
          <div class="agent-stats_title">
            {{ agentStats.taskPending }}
          </div>
          <div class="agent-stats_subtitle">
            Pending Task
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AgentView',
  props: {
    loading: Boolean,
    agentStats: Object,
    unitFundedMTDByCompany: Number,
  },
  data() {
    return {
      baseUrl: 'https://vision-crm.com'
    }
  },
  methods: {
    redirectToLeadPool() {
      console.log(this.$store.getters.getEmail)
      this.$http.get(`${this.baseUrl}/hooks/v1/get-token?email=${this.$store.getters.getEmail}`)
        .then((res) => {
          window.open(`${this.baseUrl}/login-user?token=${res.data.token}`, '_blank')
        })
        .catch(() => {
          let isNewCrm = this.$store.getters.isNewCrm
          if (isNewCrm) {
            window.open('https://app.clearmortgagecapital.com/app/lead-pool', '_blank');
          }

          if (isNewCrm === null) {
            this.pushAlert('success', "Your account has not been crated yet.")
          }

          if (!isNewCrm) {
            if (confirm('Error: You have not created an account in Vision CRM 2.0.  Would you like to make an account?')) {
              this.$http
                .get('/api/v1/crm/new-account')
                .then((res) => {
                  this.$store.dispatch('UPDATE_NEW_CRM', res.data.isNewCRM)
                  this.pushAlert('success', "Thank you! Request successfully sent.")
                })
            }
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.agent-stats {
  padding: 24px;
  border-radius: 12px;
  &.bg-light-green {
    background-color: rgba(0,215,104,0.05);
    border: 1px solid rgba(0,215,104,0.4);
  }

  &.bg-light-orange {
    border: 1px solid rgba(255,119,0,0.3);
    background-color: rgba(255,119,0,0.05)
  }

  &.bg-light-purple {
    border: 1px solid rgba(104,114,255,0.3);
    background-color: rgba(104,114,255,0.05);
  }

  &.bg-light-blue {
    border: 1px solid rgba(1,122,255,0.3);
    background-color: rgba(1,122,255,0.05);
  }

  &.bg-light-yellow {
    border: 1px solid rgba(247,181,0,0.3);
    background-color: rgba(247,181,0,0.05);
  }

  &.bg-light-grey {
    border: 1px solid rgba(149,149,149,0.3);
    background-color: rgba(149,149,149,0.05);
  }

  &.bg-light-turquoise {
    border: 1px solid rgba(60,217,192,0.3);
    background-color: rgba(60,217,192,0.05);
  }

  &.bg-light-pink {
    border: 1px solid rgba(236,34,84,0.3);
    border-radius: 12px;
    background-color: rgba(236,34,84,0.05);
  }

  &_title {
    color: rgba(0,0,0,1);
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
  }

  &_subtitle {
    color: rgba(0,0,0,1);
    font-size: 13px;
    letter-spacing: 0;
    line-height: 12px;
    margin-top: 12px;
  }
}

</style>
