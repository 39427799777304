<template>
  <div class="modal" id="addBulletins">
    <div class="modal-bg" @click="close"></div>
    <div class="modal-block">
      <div class="modal-header" v-if="ui.loading === false">
        <h3>
          Add Bulletins
        </h3>
      </div>
      <div class="text-center mt-5" v-if="ui.loading === true">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div class="modal-body" v-if="ui.loading === false">
        <div class="modal-body-container border-0">
          <div class="modal-body-container_title">
            Add New Post
          </div>
          <div class="form-group full-width">
            <label for="fieldTitle">
              Title
            </label>
            <input type="text" id="fieldTitle" class="form-control" v-model="formData.title">
          </div>
          <div class="form-group mt-3 full-width">
            <label for="fieldContent">
              Content
            </label>
            <ckeditor :editor="editor"
                      v-model="formData.content"
                      id="fieldContent"
                      :config="editorConfig">
            </ckeditor>
          </div>
          <div class="form-group full-width mt-3">
            <label for="postsList">
              Posts List
            </label>
            <div class="posts-list" id="postsList">
              <ul class="w-100 mt-3">
                <li v-for="(bullentin, index) in bulletins" :key="index">
                  <div class="row">
                    <div class="col-6">
                      <img src="@/assets/icons/icon-news.svg" alt="">
                      <span class="ms-2">{{ bullentin.title }}</span>
                    </div>
                    <div class="col-6 d-flex justify-content-end">
                      <button class="btn btn-control" @click="removePost(bullentin.id)">
                        <img src="@/assets/icons/icon-delete.svg" alt="">
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer text-left" v-if="ui.loading === false">
        <div class="d-flex justify-content-start w-100">
          <button class="btn btn-primary w-25" @click="addPost" :disabled="
                  ui.form.loading ||
                  formData.title.trim() === '' ||
                  formData.content.trim() === ''
          ">
            <span v-if="ui.form.loading"
                class="spinner-border spinner-border-sm"
                role="status" aria-hidden="true">
            </span>
            <span v-if="!ui.form.loading">Add Post</span>
          </button>
          <button class="btn btn-cancel ms-2" @click="close">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  name: 'AddBulletins',
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
      },
      formData: {
        title: '',
        content: ''
      },
      ui: {
        loading: true,
        form: {
          loading: false
        }
      },
      bulletins: [],
      statusNotification: {
        msg: null,
        status: null,
        delay: 0
      }
    }
  },
  methods: {
    getPosts() {
      this.ui.loading = true
      this.$http.get('/api/v1/bulletin/index')
      .then((res) => {
        this.ui.loading = false
        this.bulletins = res.data.bulletin
      })
    },
    addPost() {
      this.ui.form.loading = true
      let formData = new FormData()
      formData.append('title', this.formData.title)
      formData.append('content', this.formData.content)
      this.$http.post(`/api/v1/bulletin/new`, formData)
      .then(() => {
        this.formData.title = ''
        this.formData.content = ''
        this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
          msg: 'New Post added!',
          status: 'success',
          delay: 2000
        });
      }).catch((e) => {
        this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
          msg: e.response.data.message,
          status: 'error',
          delay: 2000
        });
      }).finally(() => {
        this.ui.form.loading = false
        this.getPosts()
      })
    },
    removePost(postId) {
      this.ui.loading = true
      this.$http.get(`/api/v1/bulletin/${postId}/remove`)
      .then(() => {
        this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
          msg: 'Post deleted successfully!',
          status: 'success',
          delay: 2000
        });
      }).catch((e) => {
        this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
          msg: e.response.data.message,
          status: 'error',
          delay: 2000
        });
      }).finally(() => {
        this.getPosts()
      })
    },
    close() {
      this.$root.$emit('load-tasks')
      this.$emit('close')
    }
  },
  created() {
    this.getPosts();
  }
}
</script>

<style lang="scss">
.posts-list {
  ul {
    padding: 0;
    list-style: none;
    li {
      padding: 10px 0;
      border-bottom: 1px solid rgba(231,232,232,1);
    }
  }
}

.ck.ck-editor__main>.ck-editor__editable{
  min-height: 200px!important;
  border-bottom-left-radius: 8px!important;
  border-bottom-right-radius: 8px!important;
}
.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
</style>
