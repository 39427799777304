<template>
  <div>
    <div class="page-header d-flex flex-column flex-md-row justify-content-md-between align-items-center">
      <div>
        <h3 class="page-header-title">Dashboard</h3>
        <span class="subtitle">Your daily and monthly Stats</span>
      </div>
      <div v-if="isGranted('ROLE_CEO') || isGranted('ROLE_CEO_ASSISTANT')" class="dashboard-subtitle mt-3 mt-md-2">
        <span class="subtitle mx-2">Total units funded by company</span>
        <h3 class="page-header-title">{{ totalCompanyUnits }}</h3>
      </div>
    </div>
    <div class="page-content">
      <agent-view v-if="loan.isAgent && !loan.isCEO" :agentStats="dashboard.agentStats" :unitFundedMTDByCompany="dashboard.unitFundedMTDByCompany" :loading="loan.loading"/>
      <div v-if="loan.isCEO">
        <div v-if="chartLoading" class="text-center mt-5">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div v-if="!chartLoading" class="row">
          <div class="col-sm-4">
            <div class="row">
              <div class="col-6 col-md-4">
                <div class="graph-info_title" v-text="fundsDistributed">$0</div>
                <div class="d-flex align-items-center mt-3">
                  <div class="graph-dot"></div>
                  <span class="graph-dot-explain ms-2">Funds distributed</span>
                </div>
              </div>
              <div class="col-6 col-md-4">
                <div class="graph-info_title" v-text="unitsFunded">0</div>
                <div class="d-flex align-items-center mt-3">
                  <div class="graph-dot grey"></div>
                  <span class="graph-dot-explain ms-2">Units Funded</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-8 d-flex justify-content-end text-sm-end position-relative mt-3 mt-sm-0">
            <div class="d-flex">
              <router-link :to="{name: 'loans', query: {new_loan: true}}" class="tab-btn-start">Submit a Loan</router-link>
              <router-link :to="{name: 'funding_requests_pending', query: {newFunding: true}}" class="tab-btn-center">Submit a Funding</router-link>
              <router-link :to="{name: 'pre_approval_letter_create'}" class="tab-btn-center">Generate Pre-Approval</router-link>
              <button @click="redirectToLeadPool()" class="tab-btn-end">Call Lead Pool</button>
            </div>
            <date-range-picker
              ref="picker"
              v-model="dateRange"
              :auto-apply="true"
              :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY HH:mm:ss' }"
              :timePicker="false"
              class="daterange-dashboard ms-2"
              opens="left"
              @update="prepareChartData">
              <template v-slot:input="picker">
                {{ picker.startDate | date }} - {{ picker.endDate | date }}
                <img alt="" class="position-absolute" src="@/assets/icons/icon-calender.svg">
              </template>
            </date-range-picker>
          </div>
        </div>
        <div v-if="!chartLoading" class="row">
          <div class="col-12 mt-5">
            <chart-component :chartData="chartData" :options="options"/>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <action-items
          :loading="loan.loading"
          :tasks="dashboard.tasks"
        />
        <recent-activity
          :activities="dashboard.activities"
          :loading="loan.loading"
          @showNote="showNoteModal"
        />
        <bulletins
          :bulletins="dashboard.bulletins"
          :isCEO="loan.isCEO"
          :loading="loan.loading"
          @addBulletinsModal="modals.addBulletins = true"
        />
        <top-news
          :loading="loan.loading"
          :news="dashboard.news"
        />
      </div>

    </div>
    <add-bulletins-modal
      v-if="modals.addBulletins === true"
      @close="modals.addBulletins = false"/>

    <transition mode="out-in" name="component-fade">
      <NotesModal v-if="modals.note" :loanId="activityId" @close="modals.note = false" @openTask="toggleTaskModal"/>
    </transition>
  </div>
</template>

<script>
import moment from 'moment';
import DateRangePicker from 'vue2-daterange-picker';

import ChartComponent from "./includes/Chartjs";
import AgentView from "./includes/AgentView";
import RecentActivity from "./includes/RecentActivity";
import ActionItems from "./includes/ActionItems";
import Bulletins from "./includes/Bulletins";
import TopNews from "./includes/TopNews";
import AddBulletinsModal from "./includes/AddBulletinsModal";
import NotesModal from "../loans/NotesModal";

export default {
  name: "Dashboard",
  components: {
    ChartComponent, DateRangePicker, AddBulletinsModal,
    AgentView, RecentActivity, ActionItems, Bulletins,
    TopNews, NotesModal
  },
  data() {
    return {
      loan: {
        isAgent: true,
        isCEO: false,
        loading: true
      },
      dateRange: {
        startDate: moment().startOf('year').toDate(),
        endDate: moment().toDate()
      },
      chartData: {
        datasets: [
          {
            yAxisID: 'y-axis-0',
            type: 'bar',
            data: [],
            barThickness: 25,
            hoverBackgroundColor: 'rgba(1,122,255, .8)',
            maxBarThickness: 50,
            backgroundColor: "rgba(231,232,232,1)",
            order: 2
          },
          {
            yAxisID: 'y-axis-1',
            type: 'line',
            data: [],
            pointBackgroundColor: "rgba(229,241,255,1)",
            pointBorderWidth: 2,
            pointRadius: 5,
            lineTension: 0,
            backgroundColor: "rgba(1,122,255,1)",
            borderColor: "rgba(1,122,255,1)",
            fill: false,
            order: 1
          }
        ],
        labels: []
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        cubicInterpolationMode: 'default',
        steppedLine: 'true',
        legend: {
          display: false
        },
        tooltips: {
          // mode: 'label',
          enabled: true,
          callbacks: {
            label: ((tooltipItems) => {
              if (tooltipItems.datasetIndex === 0) {
                var formatter = new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                });
                return 'Funded: ' + formatter.format(tooltipItems.yLabel)
              } else {
                return 'Units Funded: ' + tooltipItems.yLabel
              }
            })
          }
        },
        scales: {
          xAxes: [{
            maxTicksLimit: 2,
            ticks: {
              borderRadius: 12,
              padding: 20,
              autoSkip: true,
            },
            gridLines: {
              drawBorder: true,
              drawOnChartArea: false,
              drawTicks: false,
              color: "rgba(231,232,232,1)",
            },
          }],
          yAxes: [
            {
              id: 'y-axis-0',
              position: 'left',
              gridLines: {
                drawBorder: true,
                drawOnChartArea: false,
                color: "rgba(231,232,232,1)",
              },
              ticks: {
                fontColor: 'rgba(1,122,255,1)',
                fontFamily: 'Poppins',
                fontSize: 10,
                callback: this.formatNumber
              }
            },
            {
              id: 'y-axis-1',
              position: 'right',
              gridLines: {
                drawBorder: true,
                drawOnChartArea: false,
                color: "rgba(231,232,232,1)",
              },
              ticks: {
                fontColor: 'rgba(1,122,255,1)',
                fontFamily: 'Poppins',
                fontSize: 10
              }
            }
          ]
        }
      },
      modals: {
        addBulletins: false,
        note: false
      },
      dashboard: {
        rate: {
          qlRate: 'N/A',
          wfRate: 'N/A',
          baRate: 'N/A'
        },
        news: [],
        bulletins: [],
        activities: [],
        tasks: [],
        agentStats: {
          loanSubmissionsMTD: 0,
          unitTodayTotal: 0,
          unitMonthTotal: 0,
          submissions: 0,
          pipelines: 0,
          taskToday: 0,
          taskPending: 0
        },
        unitFundedMTDByCompany: 0
      },
      activityId: null,
      graphData: {},
      chartLoading: true,
      fundsDistributed: '$0',
      unitsFunded: 0,
      totalCompanyUnits: 0
    }
  },
  methods: {
    startCounter(limit) {
      let speedCount = 10;
      let limitCount = 5;
      switch (true) {
        case (limit > 5000):
          speedCount = 50;
          limitCount = 2;
          break;
        case (limit > 10000):
          speedCount = 500;
          limitCount = 1;
          break;
        case (limit > 30000):
          speedCount = 1000;
          limitCount = 0;
          break;
      }

      if (this.totalCompanyUnits < limit) {
        this.totalCompanyUnits += speedCount
        setTimeout(() => {
          this.startCounter(limit)
        }, limitCount)
      }

      if (this.totalCompanyUnits > limit) {
        this.totalCompanyUnits = limit
      }
    },
    isCEO(role) {
      if (role === true) {
        this.loan.isCEO = true;
        this.loan.isAgent = false;
      } else {
        this.loan.isCEO = false;
        this.loan.isAgent = true;
      }
    },
    loadDashboard() {
      this.loan.loading = true
      this.$http.get('/api/v1/dashboard/index')
        .then((res) => {
          this.dashboard = res.data.dashboard
          this.startCounter(res.data.companyUnits)
          if (res.data.announcements.id > 0) {
            this.$store.dispatch('OPEN_ANNOUNCEMENT_MODAL', res.data.announcements);
          }
        })
        .catch((err) => this.alertError(err.response.statusText))
        .finally(() => this.loan.loading = false)
    },
    showChart() {
      this.chartLoading = true
      this.$http.get('/api/v1/dashboard/chart/data')
        .then((res) => {
          if (res.data.chartData !== 'error') {
            this.graphData = res.data.chartData
            this.prepareChartData()
          }
        }).finally(() => {
        this.chartLoading = false
      })
    },
    prepareChartData() {
      console.log(this.dateRange)
      let startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
      let endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
      let labels = []
      let fundDistributed = []
      let unitsFunded = []
      let compareDate = moment().format('YYYY-MM-DD')
      let fundTotal = 0
      let unitTotal = 0

      if (this.graphData.fundDistributed.length > this.graphData.unitsFunded.length) {
        for (let fundDis of this.graphData.fundDistributed) {
          compareDate = moment(fundDis.dateObj).format('YYYY-MM-DD')
          if (compareDate >= startDate && compareDate <= endDate) {
            labels.push(fundDis.date)
            fundDistributed.push(fundDis.amount)
            fundTotal = fundTotal + parseInt(fundDis.amount)
          }
        }
        for (let l = 0; l < labels.length; l++) {
          let flg = true
          for (let unitsFund of this.graphData.unitsFunded) {
            if (unitsFund.date == labels[l]) {
              flg = false
              unitsFunded.push(unitsFund.amount)
              unitTotal = unitTotal + parseInt(unitsFund.amount)
            }
          }
          if (flg) {
            unitsFunded.push(0)
          }
        }
      } else {
        for (let unitsFund of this.graphData.unitsFunded) {
          compareDate = moment(unitsFund.dateObj).format('YYYY-MM-DD')
          if (compareDate >= startDate && compareDate <= endDate) {
            labels.push(unitsFund.date)
            unitsFunded.push(unitsFund.amount)
            unitTotal = unitTotal + parseInt(unitsFund.amount)
          }
        }

        for (let l = 0; l < labels.length; l++) {
          let flg = true
          for (let fundDis of this.graphData.fundDistributed) {
            if (fundDis.date == labels[l]) {
              flg = false;
              fundDistributed.push(fundDis.amount);
              fundTotal = fundTotal + parseInt(fundDis.amount);
            }
          }
          if (flg) {
            fundDistributed.push(0);
          }
        }
      }

      this.buildChart(labels, fundDistributed, unitsFunded, fundTotal, unitTotal)
    },
    buildChart(labels, fundedValue, unitValue, fundTotal, unitsTotal) {
      this.fundsDistributed = this.formatNumber(fundTotal)
      this.unitsFunded = unitsTotal
      this.chartData = {
        labels: labels,
        datasets: [
          {
            yAxisID: 'y-axis-0',
            label: 'Funded',
            type: 'bar',
            data: fundedValue,
            barThickness: 30,
            hoverBackgroundColor: 'rgba(1,122,255, .8)',
            maxBarThickness: 60,
            backgroundColor: "rgba(231,232,232,1)",
            order: 2
          },
          {
            yAxisID: 'y-axis-1',
            label: 'Units Funded',
            type: 'line',
            data: unitValue,
            pointBackgroundColor: "rgba(229,241,255,1)",
            pointBorderWidth: 2,
            pointRadius: 5,
            lineTension: 0,
            backgroundColor: "rgba(1,122,255,1)",
            borderColor: "rgba(1,122,255,1)",
            fill: false,
            order: 1
          }
        ]
      }
    },
    showNoteModal(val) {
      this.activityId = val;
      this.modals.note = true;
    },
    formatNumber(labelValue) {
      let number = Number.parseInt(labelValue)

      if (number >= 1.0e+9) {
        // Nine Zeroes for Billions
        return '$' + (number / 1.0e+9).toFixed(1) + "B"
      }

      if (number >= 1.0e+6) {
        // 6 zeros for Millions
        return '$' + (number / 1.0e+6).toFixed(2) + "M"
      }

      if (number >= 1.0e+3) {
        // 3 zeros for thousands
        return '$' + (number / 1.0e+3).toFixed(2) + "K"
      }

      return '$' + number
    },
    redirectToLeadPool() {
      let isNewCrm = this.$store.getters.isNewCrm

      if (isNewCrm) {
        window.open('https://app.clearmortgagecapital.com/app/lead-pool', '_blank');
      }

      if (isNewCrm === null) {
        this.pushAlert('success', "Your account has not been crated yet.")
      }

      if (!isNewCrm) {
        if (confirm('Error: You have not created an account in Vision CRM 2.0.  Would you like to make an account?')) {
          this.$http
            .get('/api/v1/crm/new-account')
            .then((res) => {
              this.$store.dispatch('UPDATE_NEW_CRM', res.data.isNewCRM)
              this.pushAlert('success', "Thank you! Request successfully sent.")
            })
        }
      }
    }
  },
  created() {
    this.showChart()
    this.loadDashboard()
    this.isCEO(this.isGranted("ROLE_CEO"))
  },
  beforeMount() {
    if (this.$route.query.message) {
      this.pushAlert("success", this.$route.query.message, 5000);
      this.$router.push('/');
    }
  },
  mounted: function () {
    this.$root.$on('load-tasks', () => {
      this.loadDashboard()
    })
  },
  filters: {
    date(date) {
      return moment(String(date)).format('MM/DD/YYYY')
    }
  }
}
</script>

<style lang="scss">
.page-content {
  padding: 15px 36px;
}

.dashboard-subtitle {
  @media (max-width: 568px) {
    width: 100%;
  }
}

.competitors-rate {
  padding: 14px 36px;
  color: rgba(0, 0, 0, 1);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 12px;
  border-bottom: 1px solid #E7E8E8;

  span {
    opacity: 0.46;
    color: rgba(0, 0, 0, 1);
    font-size: 10px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 10px;
  }
}

.graph-info_title {
  color: rgba(0, 0, 0, 1);
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}

.graph-dot {
  height: 14px;
  width: 14px;
  border: 2px solid rgba(1, 122, 255, 1);
  background-color: rgba(229, 241, 255, 1);
  border-radius: 50%;

  &.grey {
    background-color: rgba(237, 237, 237, 1);
    border: 2px solid rgba(237, 237, 237, 1);
  }

  &-explain {
    opacity: 0.4;
    color: rgba(0, 0, 0, 1);
    font-size: 13px;
    letter-spacing: 0;
    line-height: 12px;
    @media screen and (max-width: 478px) {
      font-size: 10px;
    }
  }
}

.daterange-dashboard {
  border-radius: 8px;
  text-align: start;
  position: relative;

  .reportrange-text {
    background: rgba(243, 243, 243, 1) !important;
    border: none;
    @media screen and (max-width: 478px) {
      font-size: 10px;
    }
  }

  .row.no-gutters {
    flex-wrap: nowrap;
  }

}


.daterange-dashboard {
  position: initial;

  th {
    font-weight: normal;

    &.month {
      color: #000;
      font-size: 13px;
      letter-spacing: 0;
      line-height: 20px;
      opacity: 1;
      font-weight: normal;
    }
  }

  td {
    width: 32px;
    height: 32px !important;

    &.active,
    &.end-date,
    &.start-date {
      border-radius: 50% !important;
      background-color: #017aff;

      &:hover {
        border-radius: 50%;
        background-color: #065ebe;
      }
    }
  }

  .daterangepicker {
    position: absolute;
    right: 0;

    .reportrange-text {
      font-size: 12px;
    }
  }
}

.reportrange-text {
  color: rgba(0, 0, 0, 1);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 25px;
}

</style>
