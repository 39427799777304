<template>
  <div class="col-md-6 mt-4">
    <div class="row mb-2">
      <div class="col-6">
        <span class="dashboard-block_title">Action Items</span>
      </div>
      <div class="col-6 text-end">
        <router-link :to="{name: 'tasks'}" class="dashboard-block_link">Show all</router-link>
      </div>
    </div>
    <div class="text-center mt-5" v-if="loading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="dashboard-block" v-else>
      <div class="text-center" v-if="tasks.length === 0">No Records Found</div>
      <div v-else>
        <div class="col-12 task" v-for="task in tasks" :key="task.id">
          <div class="row">
            <div class="col-1">
              <div class="form-group form-check-round mt-4">
                <input type="checkbox" class="form-check-input" aria-label="task-status" @click="markAsDone(task.id)">
              </div>
            </div>
            <div class="col-11 ps-0 task-body">
              <div class="row">
                <div class="col-md-11">
                  <div class="task-for">
                    Task on file <b>‘{{ task.borrower }}’</b>
                  </div>
                  <div class="task-title mt-2 cursor-pointer" @click="toggleTaskModal(task.id)">
                    {{ task.title }}
                  </div>
                  <div class="d-flex mt-2">
                    <div class="me-2 cursor-pointer" @click="toggleTaskModal(task.id, 'subTask')">
                      <img src="@/assets/icons/icon-sub-task-18.svg" alt="">
                      {{ task.subtasks }}
                    </div>
                    <div class="me-2 cursor-pointer" @click="toggleTaskModal(task.id, 'Comments')">
                      <img src="@/assets/icons/icon-comment-18.svg" alt="">
                      {{ task.comments }}
                    </div>
                    <div class="me-2 cursor-pointer" @click="toggleTaskModal(task.id, 'Attachments')">
                      <img src="@/assets/icons/icon-attachment-18.svg" alt="">
                      {{ task.attachments }}
                    </div>
                  </div>
                </div>
                <div class="col-md-1 text-end p-0">
                  <span class="date-label mt-4 d-block">{{ task.time }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActionItems',
  props: {
    loading: Boolean,
    tasks: Array
  },
  data() {
    return {
      fieldCheckTask: false,
    }
  },
  methods: {
    markAsDone(id) {
      if (confirm('Mark task is completed ?')) {
        this.$http
          .get(`/api/v1/tasks/mark/completed/${id}`)
          .then(() => this.tasks = this.tasks.filter(task => task.id !== id))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-block {
  max-height: 600px;
  min-height: 300px;
  overflow-y: auto;
  border: 1px solid rgba(231, 232, 232, 1);
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 1);
  padding: 12px 0;

  &_title {
    color: rgba(0, 0, 0, 1);
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 15px;
  }

  &_link {
    text-decoration: none;
    color: rgba(0, 120, 255, 1);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 12px;
  }

  .task-for {
    color: rgba(0, 0, 0, .6);
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 18px;

    b {
      color: rgba(0, 0, 0, 1);
      font-size: 14px;
      letter-spacing: 0;
      line-height: 21px;
    }
  }

  .task-title {
    opacity: 0.7;
    color: rgba(0, 0, 0, 1);
    font-size: 15px;
    letter-spacing: 0;
    line-height: 23px;
    margin: 0;
  }

  .date-label {
    opacity: 0.4;
    color: rgba(0, 0, 0, 1);
    font-size: 11px;
    letter-spacing: 0;
    line-height: 17px;
    text-align: right;
  }

  .row {
    width: 100%;
    margin: 0;
  }
}

.task {
  padding: 0 24px;
  transition: all .3s;

  &:nth-child(2n) {
    padding-top: 7px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, .05);
  }

  &-body {
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(231, 232, 232, 1);
  }
}
</style>
